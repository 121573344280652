var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('GAME_NAME'),"label-for":"id-gamename"}},[_c('validation-provider',{attrs:{"name":_vm.$t('GAME_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-gamename","maxlength":"300","placeholder":_vm.$t('GAME_NAME')},model:{value:(_vm.competition.c_string_swe),callback:function ($$v) {_vm.$set(_vm.competition, "c_string_swe", $$v)},expression:"competition.c_string_swe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('HINT'),"label-for":"id-hint"}},[_c('b-form-input',{attrs:{"id":"id-hint","maxlength":"45","placeholder":_vm.$t('HINT')},model:{value:(_vm.competition.hint),callback:function ($$v) {_vm.$set(_vm.competition, "hint", $$v)},expression:"competition.hint"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY'),"label-for":"id-company"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-company","placeholder":_vm.$t('SELECT_COMPANY'),"label":"companyname","clearable":false,"options":_vm.companies},on:{"input":function($event){return _vm.handleChangeCompany($event)}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Project'),"label-for":"id-project"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-project","placeholder":_vm.$t('SELECT_PROJECT'),"label":"name","clearable":false,"options":_vm.projects},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('REPORT'),"label-for":"id-report"}},[_c('validation-provider',{attrs:{"name":_vm.$t('REPORT'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-report","placeholder":_vm.$t('SELECT_REPORT_STATE'),"label":"name","clearable":false,"options":_vm.reportStates},model:{value:(_vm.reportState),callback:function ($$v) {_vm.reportState=$$v},expression:"reportState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TEAMS'),"label-for":"id-teams"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TEAMS'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-teams","placeholder":_vm.$t('SELECT_TEAMS'),"label":"name","clearable":false,"options":_vm.gameTypes},model:{value:(_vm.gameType),callback:function ($$v) {_vm.gameType=$$v},expression:"gameType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LANGUAGE'),"label-for":"id-language"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LANGUAGE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id-language","options":_vm.languageOptions,"label":"name","clearable":false},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('START_DATE'),"label-for":"id-stratdate"}},[_c('validation-provider',{attrs:{"name":_vm.$t('START_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-stratdate"},model:{value:(_vm.competition.startdate),callback:function ($$v) {_vm.$set(_vm.competition, "startdate", $$v)},expression:"competition.startdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('END_DATE'),"label-for":"id-enddate"}},[_c('validation-provider',{attrs:{"name":_vm.$t('END_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-enddate"},model:{value:(_vm.competition.enddate),callback:function ($$v) {_vm.$set(_vm.competition, "enddate", $$v)},expression:"competition.enddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TIME_ZONE'),"label-for":"id-timezone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TIME_ZONE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-timezone","placeholder":_vm.$t('TIME_ZONE'),"label":"name","clearable":false,"options":_vm.timezoneOptions},model:{value:(_vm.competition.timezone),callback:function ($$v) {_vm.$set(_vm.competition, "timezone", $$v)},expression:"competition.timezone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('SCOPE'),"label-for":"id-scope"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SCOPE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-scope","placeholder":_vm.$t('SELECT_SCOPE'),"clearable":false,"label":"name","options":_vm.scopeOptions},model:{value:(_vm.scope),callback:function ($$v) {_vm.scope=$$v},expression:"scope"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CATEGORY'),"label-for":"id-category"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CATEGORY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-category","placeholder":_vm.$t('SELECT_CATEGORY'),"label":"name","clearable":false,"options":_vm.categoryOptions},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('VISION_STATEMENT'),"label-for":"id-vision-statement"}},[_c('validation-provider',{attrs:{"name":_vm.$t('VISION_STATEMENT'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-vision-statement","maxlength":"500","placeholder":_vm.$t('VISION_STATEMENT')},model:{value:(_vm.competition.visionstatement),callback:function ($$v) {_vm.$set(_vm.competition, "visionstatement", $$v)},expression:"competition.visionstatement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"id-show_welcomePanel","name":"checkbox-10","value":"1","unchecked-value":"0"},model:{value:(_vm.competition.show_welcomePanel),callback:function ($$v) {_vm.$set(_vm.competition, "show_welcomePanel", $$v)},expression:"competition.show_welcomePanel"}},[_vm._v(" "+_vm._s(_vm.$t('SHOW_WELCOME_PANEL'))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"id-chat-enabled","name":"checkbox-chat_enabled","value":"1","unchecked-value":"0"},model:{value:(_vm.competition.chat_enabled),callback:function ($$v) {_vm.$set(_vm.competition, "chat_enabled", $$v)},expression:"competition.chat_enabled"}},[_vm._v(" "+_vm._s(_vm.$t('CHAT_ENABLED'))+" ")])],1)],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.competition.ID ? _vm.$t('BUTTON.UPDATE') : _vm.$t('BUTTON.ADD'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.RESET'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }